<script setup lang="ts">
import { FeatureFlag } from '@/types/enums'

defineProps({
  noShadow: {
    type: Boolean,
    default: false,
  },

  redirect: {
    type: Boolean,
    default: false,
  },

  onlyGuaranteedPurchase: {
    type: Boolean,
    default: false,
  },
})

const { data: flags } = useManagementFlag()
</script>

<template>
  <div
    class="flex flex-col w-full text-information-500 py-2 gap-5 bg-white"
    :class="noShadow ? '' : 'shadow rounded-lg'"
  >
    <NuxtLink
      :to="redirect ? '/compra-garantida' : ''"
      no-prefetch
      :class="!onlyGuaranteedPurchase && flags[FeatureFlag.EnableCardAuth] ? 'border-b border-b-information-500 pb-2 mb-2' : ''"
      class="flex items-center gap-4 px-4 xl:px-8"
    >
      <Img
        class="min-w-[24px]"
        src="https://web-cdn.saudetrevo.com.br/icons/icone_compra_garantida.svg"
        alt="Icone de compra garantida "
        loading="lazy"
        width="24"
        height="24"
      />

      <div>
        <strong class="font-bold text-sm">Compra garantida</strong>

        <p class="text-base">
          Devolução automática caso você não realize seu exame, dentro de 30 dias, ou cancele-o qualquer momento
          nesse período.
        </p>
      </div>
    </NuxtLink>

    <NuxtLink
      v-if="!onlyGuaranteedPurchase && flags[FeatureFlag.EnableCardAuth]"
      :to="redirect ? '/pagamento-protegido' : ''"
      no-prefetch
      class="flex items-center gap-4 px-4 xl:px-8"
    >
      <Icon
        name="mdi:info-outline"
        class="w-6 h-6 min-w-6 min-h-6 text-information-200"
        width="24"
        height="24"
      />

      <div>
        <strong class="font-bold text-sm">Pagamento protegido</strong>

        <p class="text-base">
          Seu pagamento com cartão de crédito só será efetivado após a confirmação de agendamento com o
          laboratório.
        </p>
      </div>
    </NuxtLink>
  </div>
</template>
